"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.additionalWordsBySlug = void 0;
exports.additionalWordsBySlug = {
    'Chi_veschi': [
        { index: 0, additionalWords: ['мама', 'мамы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 1, additionalWords: ['маша', 'маши'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 2, additionalWords: ['папа', 'папы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 3, additionalWords: ['саша', 'саши'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 4, additionalWords: ['ваня', 'вани'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 5, additionalWords: ['коля', 'коли'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 6, additionalWords: ['катя', 'кати'], sliceCount: 3, skip: 0, correctWord: 'котик' },
    ],
    'Plural_verbs_2': [
        { index: 0, additionalWords: ['сидят'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 1, additionalWords: ['поют'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 2, additionalWords: ['летят'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 3, additionalWords: ['едет'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 4, additionalWords: ['танцует', 'танцы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 5, additionalWords: ['ест', 'едут', 'еда'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 6, additionalWords: ['бежит', 'бег'], sliceCount: 3, skip: 0, correctWord: 'котик' },
    ],
    'chasti-tela': [
        { 'index': 0, 'word': 'Рука', 'additionalWords': ['руки', "рука"], 'sliceCount': 2, 'skip': 0, 'correctWord': 'рука' },
        { 'index': 1, 'word': 'Руки', 'additionalWords': ['рука', "руки"], 'sliceCount': 2, 'skip': 2, 'correctWord': 'руки' },
        { 'index': 2, 'word': 'Нога', 'additionalWords': ['ноги', "нога"], 'sliceCount': 2, 'skip': 4, 'correctWord': 'нога' },
        { 'index': 3, 'word': 'Ноги', 'additionalWords': ['нога', "ноги"], 'sliceCount': 2, 'skip': 6, 'correctWord': 'ноги' },
        { 'index': 4, 'word': 'туловище', 'additionalWords': ['туловище'], 'sliceCount': 1, 'skip': 8, 'correctWord': 'туловище' },
        { 'index': 5, 'word': 'голова', 'additionalWords': ['голова'], 'sliceCount': 1, 'skip': 9, 'correctWord': 'голова' },
        { 'index': 6, 'word': 'шея', 'additionalWords': ['шея'], 'sliceCount': 1, 'skip': 10, 'correctWord': 'шея' },
        { 'index': 7, 'word': 'Плечо', 'additionalWords': ['плечи', "плечо"], 'sliceCount': 2, 'skip': 11, 'correctWord': 'плечо' },
        { 'index': 8, 'word': 'Плечи', 'additionalWords': ['плечо', "плечи"], 'sliceCount': 2, 'skip': 13, 'correctWord': 'плечи' },
        { 'index': 9, 'word': 'Локоть', 'additionalWords': ['локти', "локоть"], 'sliceCount': 2, 'skip': 15, 'correctWord': 'локоть' },
        { 'index': 10, 'word': 'Локти', 'additionalWords': ['локоть', "локти"], 'sliceCount': 2, 'skip': 17, 'correctWord': 'локти' },
        { 'index': 11, 'word': 'Колено', 'additionalWords': ['колени', "колено"], 'sliceCount': 2, 'skip': 19, 'correctWord': 'колено' },
        { 'index': 12, 'word': 'Колени', 'additionalWords': ['колено', "колени"], 'sliceCount': 2, 'skip': 21, 'correctWord': 'колени' }
    ],
    'vklyuchit_chto': [
        {
            "index": 0,
            "additionalWords": ['утюга', 'утюгу', 'утюги', 'утюгом'],
            "sliceCount": 4,
            "skip": 0,
            "correctWord": 'утюг'
        },
        {
            "index": 1,
            "additionalWords": ['лампа', 'лампой', 'лампы', 'лампе'],
            "sliceCount": 4,
            "skip": 4,
            "correctWord": 'лампу'
        },
        {
            "index": 2,
            "additionalWords": ['пылесосу', 'пылесоса', 'пылесосом'],
            "sliceCount": 3,
            "skip": 8,
            "correctWord": 'пылесос'
        },
        {
            "index": 3,
            "additionalWords": ['телевизора', 'телевизором', 'телевизору', 'телевизоры'],
            "sliceCount": 4,
            "skip": 11,
            "correctWord": 'телевизор'
        }
    ],
    'vyberi_predmet_1': [
        {
            "index": 0,
            "additionalWords": ["голом", "гола", "голы", "мячом", "мяча", "голу", "мяч"],
            "sliceCount": 5,
            "skip": 0,
            "correctWord": "гол",
            "additionalCorrectWords": ["мяч", "гол"]
        },
        {
            "index": 1,
            "additionalWords": ["окна", "окном", "окон", "окну"],
            "sliceCount": 3,
            "skip": 5,
            "correctWord": "окно"
        },
        {
            "index": 2,
            "additionalWords": ["рубашки", "рубашка", "рубашкой", "рубашек"],
            "sliceCount": 4,
            "skip": 8,
            "correctWord": "рубашку"
        },
        {
            "index": 3,
            "additionalWords": ["щука", "щуки", "рыбой", "рыбы", "рыбу"],
            "sliceCount": 2,
            "skip": 12,
            "correctWord": "щуку",
            "additionalCorrectWords": ["щуку", "рыбу"]
        },
        {
            "index": 3,
            "additionalWords": ["зуб", "зубами", "зубов"],
            "sliceCount": 3,
            "skip": 12,
            "correctWord": "зубы"
        },
        {
            "index": 4,
            "additionalWords": ["гвоздём", "гвозди"],
            "sliceCount": 2,
            "skip": 15,
            "correctWord": "гвоздь"
        },
        {
            "index": 5,
            "additionalWords": ["дверью", "двери", "дверьми"],
            "sliceCount": 3,
            "skip": 17,
            "correctWord": "дверь"
        },
        {
            "index": 6,
            "additionalWords": ["платья", "платью"],
            "sliceCount": 2,
            "skip": 20,
            "correctWord": "платье"
        },
        {
            "index": 7,
            "additionalWords": ["бабочка", "бабочки", "бабочкой"],
            "sliceCount": 3,
            "skip": 22,
            "correctWord": "бабочку"
        },
        {
            "index": 8,
            "additionalWords": ["картошка", "картошки", "картошкой"],
            "sliceCount": 3,
            "skip": 25,
            "correctWord": "картошку"
        }
    ],
    'vyberi_predmet_2': [
        {
            "index": 0,
            "additionalWords": ["книга", "книгой", "книги"],
            "sliceCount": 3,
            "skip": 0,
            "correctWord": "книгу"
        },
        {
            "index": 1,
            "additionalWords": ["сыра", "сыру", "сыром", "сыры"],
            "sliceCount": 4,
            "skip": 3,
            "correctWord": "сыр"
        },
        {
            "index": 2,
            "additionalWords": ["бабушка", "бабушки", "бабушкой"],
            "sliceCount": 3,
            "skip": 7,
            "correctWord": "бабушку"
        },
        {
            "index": 3,
            "additionalWords": ["ягодами", "ягод", "ягоду"],
            "sliceCount": 3,
            "skip": 10,
            "correctWord": "ягоды"
        },
        {
            "index": 4,
            "additionalWords": ["шарфом", "шарфа", "шарфы"],
            "sliceCount": 3,
            "skip": 13,
            "correctWord": "шарф"
        },
        {
            "index": 5,
            "additionalWords": ["газета", "газетой", "газеты"],
            "sliceCount": 3,
            "skip": 16,
            "correctWord": "газету"
        },
        {
            "index": 6,
            "additionalWords": ["батона", "батоны", "батоном"],
            "sliceCount": 3,
            "skip": 19,
            "correctWord": "батон"
        },
        {
            "index": 7,
            "additionalWords": ["дедушка", "дедушки", "дедушкой"],
            "sliceCount": 3,
            "skip": 22,
            "correctWord": "дедушку"
        },
        {
            "index": 8,
            "additionalWords": ["гриб", "грибами", "грибу"],
            "sliceCount": 3,
            "skip": 25,
            "correctWord": "грибы"
        },
        {
            "index": 9,
            "additionalWords": ["шапка", "шапкой", "шапки"],
            "sliceCount": 3,
            "skip": 28,
            "correctWord": "шапку"
        }
    ],
    'vyberi_predmet_3': [
        {
            "index": 0,
            "additionalWords": ["стакана", "стаканы", "стаканом", "стаканы"],
            "sliceCount": 4,
            "skip": 0,
            "correctWord": "стакан"
        },
        {
            "index": 1,
            "additionalWords": ["пуговица", "пуговицы", "пуговичной"],
            "sliceCount": 3,
            "skip": 4,
            "correctWord": "пуговицу"
        },
        {
            "index": 2,
            "additionalWords": ["забора", "забору", "забором"],
            "sliceCount": 3,
            "skip": 7,
            "correctWord": "забор"
        },
        {
            "index": 3,
            "additionalWords": ["велосипеда", "велосипедом"],
            "sliceCount": 2,
            "skip": 10,
            "correctWord": "велосипед"
        },
        {
            "index": 4,
            "additionalWords": ["змея", "змеи", "змеем"],
            "sliceCount": 3,
            "skip": 12,
            "correctWord": "змею"
        },
        {
            "index": 5,
            "additionalWords": ["чашкой", "чашка", "чашке"],
            "sliceCount": 3,
            "skip": 15,
            "correctWord": "чашку"
        },
        {
            "index": 6,
            "additionalWords": ["тёсьма", "тёсьмой", "тёсьмы"],
            "sliceCount": 3,
            "skip": 18,
            "correctWord": "тёщу"
        },
        {
            "index": 7,
            "additionalWords": ["крыша", "крыше", "крышой"],
            "sliceCount": 3,
            "skip": 21,
            "correctWord": "крышу"
        },
        {
            "index": 8,
            "additionalWords": ["кольца", "кольцом", "колец"],
            "sliceCount": 3,
            "skip": 24,
            "correctWord": "кольцо"
        },
        {
            "index": 9,
            "additionalWords": ["медведь", "медведю", "медведем"],
            "sliceCount": 3,
            "skip": 27,
            "correctWord": "медведь"
        }
    ],
    'vyberi_predmet_4': [
        {
            "index": 0,
            "additionalWords": ["рука", "рукой", "руками", "руку", "руке", "рукам"],
            "sliceCount": 6,
            "skip": 0,
            "correctWord": "руки"
        },
        {
            "index": 1,
            "additionalWords": ["супа", "супом", "супу"],
            "sliceCount": 3,
            "skip": 6,
            "correctWord": "суп"
        },
        {
            "index": 2,
            "additionalWords": ["соки", "соком", "соку"],
            "sliceCount": 3,
            "skip": 9,
            "correctWord": "сок"
        },
        {
            "index": 3,
            "additionalWords": ["стула", "стулья", "стулом", "стулу"],
            "sliceCount": 4,
            "skip": 12,
            "correctWord": "стул"
        },
        {
            "index": 4,
            "additionalWords": ["колбаса", "колбасой", "колбасе", "колбасы"],
            "sliceCount": 4,
            "skip": 16,
            "correctWord": "колбасу"
        },
        {
            "index": 5,
            "additionalWords": ["ушами", "ушам", "ухо"],
            "sliceCount": 3,
            "skip": 20,
            "correctWord": "уши"
        },
        {
            "index": 6,
            "additionalWords": ["каша", "кашой", "каше", "каши", "кашей"],
            "sliceCount": 5,
            "skip": 23,
            "correctWord": "кашу"
        },
        {
            "index": 7,
            "additionalWords": ["вода", "водой", "воде"],
            "sliceCount": 3,
            "skip": 28,
            "correctWord": "воду"
        },
        {
            "index": 8,
            "additionalWords": ["краном", "крана", "крану"],
            "sliceCount": 3,
            "skip": 31,
            "correctWord": "кран"
        },
        {
            "index": 9,
            "additionalWords": ["лимона", "лимоном", "лимону"],
            "sliceCount": 3,
            "skip": 34,
            "correctWord": "лимон"
        }
    ],
    'Poschitaem_predmety_2': [
        {
            "index": 0,
            "correctWord": "комната",
            "additionalWords": ["комнаты", "комнат", "комнатой", "комнату", "комнате"],
            "additionalCorrectWords": [],
        },
        {
            "index": 1,
            "correctWord": "комнаты",
            "additionalWords": ["комната", "комнат", "комнатой", "комнату", "комнате"],
            "additionalCorrectWords": [],
        },
        {
            "index": 2,
            "correctWord": "комнат",
            "additionalWords": ["комнаты", "комната", "комнатой", "комнату", "комнате"],
            "additionalCorrectWords": [],
        },
        {
            "index": 3,
            "correctWord": "балкон",
            "additionalWords": ["балкона", "балконом", "балконов", "балконы"],
            "additionalCorrectWords": [],
        },
        {
            "index": 4,
            "correctWord": "балкона",
            "additionalWords": ["балкон", "балконом", "балконов", "балконы"],
            "additionalCorrectWords": [],
        },
        {
            "index": 5,
            "correctWord": "балконов",
            "additionalWords": ["балкон", "балкона", "балконом", "балконы"],
            "additionalCorrectWords": [],
        },
        {
            "index": 6,
            "correctWord": "доска",
            "additionalWords": ["доски", "досок", "доской"],
            "additionalCorrectWords": [],
        },
        {
            "index": 7,
            "correctWord": "доски",
            "additionalWords": ["доска", "досок", "доской"],
            "additionalCorrectWords": [],
        },
        {
            "index": 8,
            "correctWord": "досок",
            "additionalWords": ["доски", "доска", "доской"],
            "additionalCorrectWords": [],
        },
        {
            "index": 9,
            "correctWord": "нож",
            "additionalWords": ["ножа", "ножки", "ножкой", "ножке", "ножи"],
            "additionalCorrectWords": [],
        },
        {
            "index": 10,
            "correctWord": "ножи",
            "additionalWords": ["ножа", "ножки", "ножкой", "ножке", "нож"],
            "additionalCorrectWords": [],
        },
        {
            "index": 11,
            "correctWord": "шкаф",
            "additionalWords": ["шкафа", "шкафу", "шкафом", "шкафы", "шкафов"],
            "additionalCorrectWords": [],
        },
        {
            "index": 12,
            "correctWord": "шкафы",
            "additionalWords": ["шкаф", "шкафа", "шкафу", "шкафом", "шкафов"],
            "additionalCorrectWords": [],
        }
    ],
    'Poschitaem_predmety': [
        {
            "index": 0,
            "correctWord": "стол",
            "additionalCorrectWords": [],
            "additionalWords": ["столы", "стола", "столов", "столом", "столу"]
        },
        {
            "index": 1,
            "correctWord": "стола",
            "additionalCorrectWords": [],
            "additionalWords": ["стол", "столы", "столов", "столом", "столу"]
        },
        {
            "index": 2,
            "correctWord": "столов",
            "additionalCorrectWords": [],
            "additionalWords": ["стол", "столы", "стола", "столом", "столу"]
        },
        {
            "index": 3,
            "correctWord": "окно",
            "additionalCorrectWords": [],
            "additionalWords": ["окна", "окну", "окон", "окном", "окон"]
        },
        {
            "index": 4,
            "correctWord": "окна",
            "additionalCorrectWords": [],
            "additionalWords": ["окно", "окну", "окон", "окном", "окон"]
        },
        {
            "index": 5,
            "correctWord": "окон",
            "additionalCorrectWords": [],
            "additionalWords": ["окно", "окна", "окну", "окном", "окон"]
        },
        {
            "index": 6,
            "correctWord": "стул",
            "additionalCorrectWords": [],
            "additionalWords": ["стула", "стульев", "стулом", "стулья"]
        },
        {
            "index": 7,
            "correctWord": "стула",
            "additionalCorrectWords": [],
            "additionalWords": ["стул", "стульев", "стулом", "стулья"]
        },
        {
            "index": 8,
            "correctWord": "стульев",
            "additionalCorrectWords": [],
            "additionalWords": ["стул", "стула", "стулом", "стулья"]
        },
        {
            "index": 9,
            "correctWord": "дверь",
            "additionalCorrectWords": [],
            "additionalWords": ["двери", "дверей", "дверью", "дверями"]
        },
        {
            "index": 10,
            "correctWord": "двери",
            "additionalCorrectWords": [],
            "additionalWords": ["дверь", "дверей", "дверью", "дверями"]
        },
        {
            "index": 11,
            "correctWord": "дверей",
            "additionalCorrectWords": [],
            "additionalWords": ["дверь", "двери", "дверью", "дверями"]
        },
        {
            "index": 12,
            "correctWord": "полка",
            "additionalCorrectWords": [],
            "additionalWords": ["полки", "полкой", "полок"]
        },
        {
            "index": 13,
            "correctWord": "полки",
            "additionalCorrectWords": [],
            "additionalWords": ["полка", "полкой", "полок"]
        },
        {
            "index": 14,
            "correctWord": "полок",
            "additionalCorrectWords": [],
            "additionalWords": ["полка", "полки", "полкой"]
        }
    ],
    'Roditelny_Vinitelny_1': [
        {
            "index": 0,
            "correctWord": "лису",
            "additionalWords": ["лиса", "лисы", "лисой", "лисе"],
            "additionalCorrectWords": []
        },
        {
            "index": 1,
            "correctWord": "лисёнка",
            "additionalWords": ["лисёнок", "лисёнку", "лисёнком"],
            "additionalCorrectWords": []
        },
        {
            "index": 2,
            "correctWord": "утку",
            "additionalWords": ["утка", "утки", "уткой", "утке"],
            "additionalCorrectWords": []
        },
        {
            "index": 3,
            "correctWord": "лебедя",
            "additionalWords": ["лебедь", "лебедю", "лебедем"],
            "additionalCorrectWords": []
        },
        {
            "index": 4,
            "correctWord": "обезьяну",
            "additionalWords": ["обезьяна", "обезьяны", "обезьяной", "обезьяне"],
            "additionalCorrectWords": []
        },
        {
            "index": 5,
            "correctWord": "змею",
            "additionalWords": ["змея", "змеи", "змеей", "змее"],
            "additionalCorrectWords": []
        },
        {
            "index": 6,
            "correctWord": "собаки",
            "additionalWords": ["собака", "собакой", "собаке"],
            "additionalCorrectWords": []
        },
        {
            "index": 7,
            "correctWord": "жирафа",
            "additionalWords": ["жираф", "жирафом", "жирафу"],
            "additionalCorrectWords": []
        },
        {
            "index": 8,
            "correctWord": "птенцов",
            "additionalWords": ["птенец", "птенцом", "птенцам", "птенцами", "птенеч"],
            "additionalCorrectWords": []
        },
        {
            "index": 9,
            "correctWord": "волка",
            "additionalWords": ["волк", "волку", "волком"],
            "additionalCorrectWords": []
        },
        {
            "index": 10,
            "correctWord": "медведя",
            "additionalWords": ["медведь", "медведю", "медведем"],
            "additionalCorrectWords": []
        },
        {
            "index": 11,
            "correctWord": "дельфинов",
            "additionalWords": ["дельфин", "дельфинам", "дельфинами"],
            "additionalCorrectWords": []
        },
        {
            "index": 12,
            "correctWord": "китов",
            "additionalWords": ["кит", "китам", "китами"],
            "additionalCorrectWords": []
        },
        {
            "index": 13,
            "correctWord": "кита",
            "additionalWords": ["кит", "киту", "китом"],
            "additionalCorrectWords": []
        },
        {
            "index": 14,
            "correctWord": "рыб",
            "additionalWords": ["рыба", "рыбе", "рыбой"],
            "additionalCorrectWords": []
        }
    ],
    'naidi_svyaz_11': [
        {
            "index": 0,
            "correctWord": "конь и седло",
            "additionalWords": [],
            "additionalCorrectWords": ["конь и седло", "седло и конь"]
        },
        {
            "index": 1,
            "correctWord": "малина и варенье",
            "additionalWords": [],
            "additionalCorrectWords": ["малина и варенье", "варенье и малина"]
        },
        {
            "index": 2,
            "correctWord": "нитки и пуговицы",
            "additionalWords": [],
            "additionalCorrectWords": ["нитки и пуговицы", "пуговицы и нитки"]
        },
        {
            "index": 3,
            "correctWord": "овощи и суп",
            "additionalWords": [],
            "additionalCorrectWords": ["овощи и суп", "суп и овощи"]
        },
        {
            "index": 4,
            "correctWord": "пальма и бананы",
            "additionalWords": [],
            "additionalCorrectWords": ["пальма и бананы", "бананы и пальма"]
        },
        {
            "index": 5,
            "correctWord": "паровоз и вагон",
            "additionalWords": [],
            "additionalCorrectWords": ["паровоз и вагон", "вагон и паровоз"]
        },
        {
            "index": 6,
            "correctWord": "повар и суп",
            "additionalWords": [],
            "additionalCorrectWords": ["повар и суп", "суп и повар"]
        },
        {
            "index": 7,
            "correctWord": "ракета и космонавт",
            "additionalWords": [],
            "additionalCorrectWords": ["ракета и космонавт", "космонавт и ракета"]
        },
        {
            "index": 8,
            "correctWord": "удочка и рыба",
            "additionalWords": [],
            "additionalCorrectWords": ["удочка и рыба", "рыба и удочка"]
        },
        {
            "index": 9,
            "correctWord": "футбол и судья",
            "additionalWords": [],
            "additionalCorrectWords": ["футбол и судья", "судья и футбол"]
        },
        {
            "index": 10,
            "correctWord": "цирк и фокусник",
            "additionalWords": [],
            "additionalCorrectWords": ["цирк и фокусник", "фокусник и цирк"]
        }
    ],
    'naidi_svyaz_10': [
        {
            "index": 0,
            "correctWord": "утка и петух",
            "additionalWords": [],
            "additionalCorrectWords": ["утка и петух", "петух и утка"]
        },
        {
            "index": 1,
            "correctWord": "мёд и соты",
            "additionalWords": [],
            "additionalCorrectWords": ["мёд и соты", "соты и мёд"]
        },
        {
            "index": 2,
            "correctWord": "топор и пень",
            "additionalWords": [],
            "additionalCorrectWords": ["топор и пень", "пень и топор"]
        },
        {
            "index": 3,
            "correctWord": "курица и цыплёнок",
            "additionalWords": [],
            "additionalCorrectWords": ["курица и цыплёнок", "цыплёнок и курица"]
        },
        {
            "index": 4,
            "correctWord": "губы и глаза",
            "additionalWords": [],
            "additionalCorrectWords": ["губы и глаза", "глаза и губы"]
        },
        {
            "index": 5,
            "correctWord": "художник и краски",
            "additionalWords": [],
            "additionalCorrectWords": ["художник и краски", "краски и художник"]
        },
        {
            "index": 6,
            "correctWord": "жаба и кувшинка",
            "additionalWords": [],
            "additionalCorrectWords": ["жаба и кувшинка", "кувшинка и жаба"]
        },
        {
            "index": 7,
            "correctWord": "паровоз и вагон",
            "additionalWords": [],
            "additionalCorrectWords": ["паровоз и вагон", "вагон и паровоз"]
        },
        {
            "index": 8,
            "correctWord": "пианист и певец",
            "additionalWords": [],
            "additionalCorrectWords": ["пианист и певец", "певец и пианист"]
        },
        {
            "index": 9,
            "correctWord": "сапожник и обувь",
            "additionalWords": [],
            "additionalCorrectWords": ["сапожник и обувь", "обувь и сапожник"]
        }
    ],
    'launch_glagol': [
        {
            "index": 0,
            "correctWord": "слушает",
            "additionalCorrectWords": ["слушает"],
            "additionalWords": [],
        },
        {
            "index": 1,
            "correctWord": "лил",
            "additionalCorrectWords": ["лил", "шёл"],
            "additionalWords": [],
        },
        {
            "index": 2,
            "correctWord": "вышло",
            "additionalCorrectWords": ["вышло", "светит"],
            "additionalWords": [],
        },
        {
            "index": 3,
            "correctWord": "лечит",
            "additionalCorrectWords": ["лечит"],
            "additionalWords": [],
        },
        {
            "index": 4,
            "correctWord": "строят",
            "additionalCorrectWords": ["строят"],
            "additionalWords": [],
        },
        {
            "index": 5,
            "correctWord": "играть",
            "additionalCorrectWords": ["играть"],
            "additionalWords": [],
        },
        {
            "index": 6,
            "correctWord": "проходит",
            "additionalCorrectWords": ["проходит", "идёт"],
            "additionalWords": [],
        },
        {
            "index": 7,
            "correctWord": "идём",
            "additionalCorrectWords": ["идём", "пойдём"],
            "additionalWords": []
        },
        {
            "index": 8,
            "correctWord": "закажем",
            "additionalCorrectWords": ["закажем"],
            "additionalWords": []
        },
        {
            "index": 9,
            "correctWord": "принес",
            "additionalCorrectWords": ["принес", "привёз", "доставил", "принёс"],
            "additionalWords": []
        }
    ]
};
